@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Figtree:400,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+Thai:400,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Pacifico:400,500,600,700,800,900);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'IBM-Plex-Sans-Arabic',
    'Kanit' 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'IBM-Plex-Sans-Arabic';
  src: local('IBM-Plex-Sans-Arabic'),
    url(https://people.playtorium.co.th/static/media/IBMPlexSansArabic-Bold.b1b15ce5.ttf)
      format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'IBM-Plex-Sans-Arabic';
  src: local('IBM-Plex-Sans-Arabic'),
    url(https://people.playtorium.co.th/static/media/IBMPlexSansArabic-ExtraLight.46144b67.ttf)
      format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'IBM-Plex-Sans-Arabic';
  src: local('IBM-Plex-Sans-Arabic'),
    url(https://people.playtorium.co.th/static/media/IBMPlexSansArabic-Light.6811b088.ttf)
      format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'IBM-Plex-Sans-Arabic';
  src: local('IBM-Plex-Sans-Arabic'),
    url(https://people.playtorium.co.th/static/media/IBMPlexSansArabic-Medium.60616769.ttf)
      format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'IBM-Plex-Sans-Arabic';
  src: local('IBM-Plex-Sans-Arabic'),
    url(https://people.playtorium.co.th/static/media/IBMPlexSansArabic-Regular.678e89cc.ttf)
      format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'IBM-Plex-Sans-Arabic';
  src: local('IBM-Plex-Sans-Arabic'),
    url(https://people.playtorium.co.th/static/media/IBMPlexSansArabic-SemiBold.87e94a2f.ttf)
      format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'IBM-Plex-Sans-Arabic';
  src: local('IBM-Plex-Sans-Arabic'),
    url(https://people.playtorium.co.th/static/media/IBMPlexSansArabic-Text.53a10d67.ttf)
      format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'IBM-Plex-Sans-Arabic';
  src: local('IBM-Plex-Sans-Arabic'),
    url(https://people.playtorium.co.th/static/media/IBMPlexSansArabic-Thin.4f436c36.ttf)
      format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Kanit';
  src: local('Kanit'),
    url(https://people.playtorium.co.th/static/media/Kanit-Black.74602dc6.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Kanit';
  src: local('Kanit'),
    url(https://people.playtorium.co.th/static/media/Kanit-BlackItalic.886705ad.ttf) format('truetype');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'Kanit';
  src: local('Kanit'),
    url(https://people.playtorium.co.th/static/media/Kanit-ExtraBold.b9bc9ce3.ttf) format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Kanit';
  src: local('Kanit'),
    url(https://people.playtorium.co.th/static/media/Kanit-ExtraBoldItalic.34836fa1.ttf) format('truetype');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'Kanit';
  src: local('Kanit'),
    url(https://people.playtorium.co.th/static/media/Kanit-Bold.064d3b2f.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Kanit';
  src: local('Kanit'),
    url(https://people.playtorium.co.th/static/media/Kanit-BoldItalic.e10eb7ff.ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Kanit';
  src: local('Kanit'),
    url(https://people.playtorium.co.th/static/media/Kanit-SemiBold.cfa4a19a.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Kanit';
  src: local('Kanit'),
    url(https://people.playtorium.co.th/static/media/Kanit-SemiBoldItalic.31f37c12.ttf) format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Kanit';
  src: local('Kanit'),
    url(https://people.playtorium.co.th/static/media/Kanit-Medium.572f0aba.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Kanit';
  src: local('Kanit'),
    url(https://people.playtorium.co.th/static/media/Kanit-MediumItalic.f9da6922.ttf) format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Kanit';
  src: local('Kanit'),
    url(https://people.playtorium.co.th/static/media/Kanit-Regular.a82c4c3b.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Kanit';
  src: local('Kanit'),
    url(https://people.playtorium.co.th/static/media/Kanit-Italic.729f2f68.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Kanit';
  src: local('Kanit'),
    url(https://people.playtorium.co.th/static/media/Kanit-Light.a1433e7d.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Kanit';
  src: local('Kanit'),
    url(https://people.playtorium.co.th/static/media/Kanit-LightItalic.b9c488ee.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Kanit';
  src: local('Kanit'),
    url(https://people.playtorium.co.th/static/media/Kanit-ExtraLight.f632527d.ttf) format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Kanit';
  src: local('Kanit'),
    url(https://people.playtorium.co.th/static/media/Kanit-ExtraLightItalic.ac113281.ttf) format('truetype');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Kanit';
  src: local('Kanit'),
    url(https://people.playtorium.co.th/static/media/Kanit-Thin.e9104ee3.ttf) format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Kanit';
  src: local('Kanit'),
    url(https://people.playtorium.co.th/static/media/Kanit-ThinItalic.edad91f1.ttf) format('truetype');
  font-weight: 100;
  font-style: italic;
}
/* shdghoskdfhdjkfjhjk */
@font-face {
  font-family: 'Inter';
  src: local('Inter'),
    url(https://people.playtorium.co.th/static/media/Inter-Black.de70a8a9.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter'),
    url(https://people.playtorium.co.th/static/media/Inter-ExtraBold.c88d8bbb.ttf) format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter'),
    url(https://people.playtorium.co.th/static/media/Inter-Bold.c73899df.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter'),
    url(https://people.playtorium.co.th/static/media/Inter-SemiBold.65cbb751.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter'),
    url(https://people.playtorium.co.th/static/media/Inter-Medium.ddc03dcd.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter'),
    url(https://people.playtorium.co.th/static/media/Inter-Regular.56786aa1.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter'),
    url(https://people.playtorium.co.th/static/media/Inter-Light.5a8cd28e.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter'),
    url(https://people.playtorium.co.th/static/media/Inter-ExtraLight.b77a9847.ttf) format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter'),
    url(https://people.playtorium.co.th/static/media/Inter-Thin.8f6b453e.ttf) format('truetype');
  font-weight: 100;
  font-style: normal;
}

/* antd */
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 16px 16px;
  word-break: break-word;
  -ms-word-break: break-all;
}

body {
  min-width: 420px !important;
}

:root {
  --secondary-blue: #32344a;
  --primary-blue: #1b1d36;
  --primary-black: #333333;
  --secondary-red: #f4364c;
  --primary-green: #08b424;
  --tertiary-blue: #4a80c9;

  --white: #ffffff;
  --grey: #52575c;
  --orange: #f46f36;
  --placeholder: #b0b0b0;
  --stroke: #e8e8eb;
  --disabled: #e8e8e8;
  --background: #f7f7f7;
  --finished: #4a80c9;

  --text-primary: #32429d;
  --text-error: #f74c25;
  --text-placeholder: #c9c9c9;
  --text-normal: #303030;
}

@media only screen and (max-width: 600px) {
  .header-topic {
    display: none !important;
  }
}

@media (max-width: 959px) {
  .custom-col {
    border-radius: 10px !important;
  }
}

.ant-layout-header.header-container {
  background: #fff;
  padding: 0 32px 0;
}

.header-topic {
  display: block;
}

.text-secondary-blue {
  color: var(--secondary-blue);
}

.text-primary-blue {
  color: var(--primary-blue);
}

.text-primary-black {
  color: var(--primary-black);
}

.text-secondary-red {
  color: var(--secondary-red);
}

.text-primary-green {
  color: var(--primary-green);
}

.text-tertiary-blue {
  color: var(--tertiary-blue);
}

.text-secondary-orange {
  color: var(--orange);
}

.text-normal {
  color: var(--text-normal);
}

.text-primary {
  color: var(--text-primary);
}

.text-grey {
  color: var(--grey);
}

.text-white {
  color: var(--white);
}

.text-placeholder {
  color: var(--text-placeholder);
}

.text-require {
  color: var(--secondary-red);
  margin-left: 4px;
}

.text-support {
  color: var(--finished);
}

.text-error {
  color: var(--text-error);
}

.card-container {
  padding: 16px;
  background-color: var(--white);
  border: 1px solid var(--stroke);
  border-radius: 4px;
}

.card-container-bg-grey {
  padding: 16px;
  background-color: var(--background);
  border: 1px solid var(--stroke);
  border-radius: 4px;
}

.card-container-bg {
  padding: 16px;
  background: linear-gradient(101deg, #f4364c 0%, #b22838 100%);
  border: 1px solid var(--stroke);
  border-radius: 4px;
}

.card-level {
  padding: 8px 16px;
  background-color: #eddbd7;
  background-color: #fde1e4;
  border: 1px solid var(--secondary-red);
  border-radius: 4px;
}

.card-container-dash-border {
  padding: 16px;
  background-color: var(--white);
  border: 1px solid var(--stroke);
  border-radius: 2px;
  border-style: dashed;
}

.bg-color {
  background-color: var(--background);
}

.bg-white {
  background-color: var(--white);
}

.bg-primary-blue {
  background-color: var(--primary-blue);
}

.bg-secondary-red {
  background-color: var(--secondary-red);
}

.button-primary {
  background-color: var(--primary-blue);
  border: none;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  font-family: 'Figtree';
}

.button-primary-round {
  color: var(--white);
  padding: 8px, 16px, 8px, 16px;
  background-color: var(--primary-blue);
  border: 1px solid;
  border-radius: 4px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  font-family: 'Figtree';
}

.button-primary-red {
  background-color: var(--secondary-red);
  border: none;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  font-family: 'Figtree';
}

.button-outlined {
  background-color: var(--white);
  border: 1px solid var(--primary-blue);
  color: var(--primary-blue);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  font-family: 'Figtree';
}

.button-outlined-stroke {
  padding: 8px, 16px, 8px, 16px;
  background-color: var(--white);
  border: 1px solid var(--stroke);
  border-radius: 4px;
  color: var(--primary-blue);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  font-family: 'Figtree';
}

.button-red {
  background-color: var(--white);
  border: 1px solid var(--secondary-red);
  color: var(--secondary-red);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  font-family: 'Figtree';
}

.button-link {
  color: var(--primary-blue);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  font-family: 'Figtree';
}

.button-link:hover {
  color: var(--primary-blue);
}

.button-primary:hover {
  background-color: #32355a;
}

.button-primary:focus {
  background-color: var(--primary-blue);
}

.button-primary-round:hover {
  background-color: #32355a;
}

.button-primary-round:focus {
  background-color: var(--primary-blue);
}

.button-primary-red:hover {
  background-color: #e33347;
}

.button-primary-red:focus {
  background-color: var(--secondary-red);
}

.button-outlined:hover {
  border: 1px solid #32355a;
  color: var(--primary-blue);
}

.button-outlined-stroke:hover {
  border: 1px solid var(--primary-blue);
  color: var(--primary-blue);
}

.button-outlined:focus {
  border: 1px solid var(--primary-blue);
  background-color: var(--white);
  color: var(--primary-blue);
}

.button-red:hover {
  border: 1px solid #d80019;
  color: #d80019;
}

.button-red:focus {
  border: 1px solid var(--secondary-red);
  background-color: var(--white);
  color: var(--secondary-red);
}

.button-outlined-stroke:focus {
  border: 1px solid var(--primary-blue);
  color: var(--primary-blue);
}

.switch-primary.ant-switch-checked {
  background: var(--secondary-red);
}

.switch-primary {
  font-size: 12px;
  font-family: 'Kanit';
}

.ant-select-item-option-content {
  font-size: 12px;
  line-height: 24px;
  font-family: Figtree, 'Noto Sans Thai', sans-serif !important;
}

.ant-radio-group {
  font-family: Figtree, 'Noto Sans Thai', sans-serif !important;
}

.ant-checkbox-checked.ant-checkbox-inner {
  background-color: var(--secondary-red);
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--secondary-red);
}

.ant-tabs-ink-bar {
  background: var(--secondary-red);
}

.ant-pagination {
  font-family: Figtree, ' Noto Sans Thai ', sans-serif !important;
  font-size: 12px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector {
  font-family: Figtree, ' Noto Sans Thai ', sans-serif !important;
  font-size: 12px;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  height: auto;
  padding: 0 0 5px 0;
  line-height: 18px;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  font-family: Figtree, 'Noto Sans Thai', sans-serif !important;
}

.ant-picker-input > input {
  font-size: 12px;
  font-family: Figtree, 'Noto Sans Thai', sans-serif !important;
}

.ant-input-group .ant-input {
  font-size: 12px;
  font-family: Figtree, 'Noto Sans Thai', sans-serif !important;
}

.ant-select-selector {
  font-size: 12px;
  font-family: Figtree, 'Noto Sans Thai', sans-serif !important;
}

.ant-input {
  font-size: 12px;
  font-family: Figtree, 'Noto Sans Thai', sans-serif !important;
}

.ant-tabs-tab {
  font-size: 12px;
  font-family: Figtree, 'Noto Sans Thai', sans-serif !important;
}

.ant-message {
  font-size: 12px;
  font-family: Figtree, 'Noto Sans Thai', sans-serif !important;
}

.ant-collapse-header-text {
  font-size: 12px;
  font-family: Figtree, 'Noto Sans Thai', sans-serif !important;
}

.ant-input-number-input {
  font-size: 12px;
  font-family: Figtree, 'Noto Sans Thai', sans-serif !important;
}

.ant-input-affix-wrapper > .ant-input {
  font-size: 12px;
}

.ant-modal-body {
  padding: 14px 24px 10px 24px;
}

.ant-modal-footer {
  padding: 10px 24px;
}

/* .ant-tabs-tab:hover {
  color: var(--secondary-red);
} */

.tabs-primary {
  padding: 8px 16px 16px 16px;
  background-color: var(--white);
  border: 1px solid var(--stroke);
  border-radius: 4px;
}

.weekend-cell {
  background-color: #e6e6e6;
}

.holiday-cell {
  background-color: #ffcacb;
}

.normal-cell {
  background-color: var(--white);
}

.icon-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #4a80c9;
  margin-right: 5px;
}

.custom-alert .ant-alert-message {
  color: #f4364c;
}

.custom-alert .ant-alert-icon {
  color: #f4364c;
}

.custom-info .ant-alert-message {
  color: #4a80c9;
}

.custom-info .ant-alert-icon {
  color: #4a80c9;
}

